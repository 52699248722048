<template>
    <div class="IbkNoticeBox">
        <p class="IbkNoticeBox_title">꼭 알아두세요 ▲</p>
        <ul class="IbkNoticeBox_list">
            <li>내집스캔은 i-ONE 자산관리 가입자에게 시범 운영기간동안 카카오로그인 계정으로 인당 리포트발급 1개, 알리미 신청 1개 무료이용이 가능합니다.</li>
            <li>내집스캔은 아파트, 오피스텔, 다세대/연립주택만 이용 가능하며, 시세가 없는 경우가 있을 수 있습니다.</li>
            <li>내집스캔의 전세사기 위험도를 진단하는 리포트 내용은 ㈜한국부동산데이터연구소가 제공 및 관리하고 있으며, 기업은행은 서비스 페이지에 연결하는 역할만 하며, 서비스의 제공과 그 책임은 위탁사인 ㈜한국부동산데이터연구소에 있습니다.</li>
            <li>내집스캔의 리포트 및 서비스 전반에 대한 자세한 문의는 ㈜한국부동산데이터연구소 고객센터(070-8648-1890)와 카카오톡 채널 @내집스캔으로 문의하시기 바랍니다.</li>
            <li>본 서비스는 기업은행의 사정에 따라 변경/중단될 수 있습니다.</li>
        </ul>
    </div>
</template>

<script>
export default {
    name :"IbkNoticeBox",
}
</script>

<style scoped>
.IbkNoticeBox_title {
    color: black !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    margin-top: 40px !important;
    margin-bottom: 10px !important;
    text-align: left !important;
}
.IbkNoticeBox {
    padding: 0 20px;
    margin-bottom: 80px;
}
p {
    font-size: 14px;
    font-weight: 700;
}
.IbkNoticeBox_list {
    padding: 0 20px;
}

.IbkNoticeBox_list li {
    list-style-type: disc;
    line-height: 2.2;
    font-size: 12px;
    text-align: left;
}
</style>