import store from "../index.js";
import api from "../../utils/api.js";

export const modules = {
    store,
};

export const state = {
    _nzParams: {
        nz_source: '',
        nz_medium: '',
        nz_campaign: '',
    },
    _nz_source: null,
    validationError: null,
    userSignupSource: null,
    _error: {
        code: null,
        message: null,
    },
};

export const mutations = {
    setNzParamsDecryptData(state, item) {
        state._nzParams = {};
        state._nzParams = Object.assign({}, item);
    },
    setNzSource(state, item) {
        state._nz_source = item;
    },
    setValidationError(state, item) {
        state.validationError = item;
    },
    setUserSignupSource(state, item) {
        state.userSignupSource = item;
    },
    setError(state, error) {
        state._error = error;
    },
    setClearError(state) {
        state._error = {
            code: null,
            message: null,
        };
    }
};

export const actions = {
    async acNzParamsDecrypt({commit}, item) {
        const url = `/api/v1/order/coupon/validate`;
        try {
            const res = await api.post(url, {nzToken: item});
            if (res?.data?.code === 0) {
                commit('setNzParamsDecryptData', res.data.data);
                return res.data;
            }
        } catch (error) {
            return false;
        }
    },
    acInitializeNzSource({ commit }) {
        const nz_source = sessionStorage.getItem('nz_source');
        if (nz_source) {
            commit('setNzSource', nz_source);
        }
    },
    async acSubscribePaymentCampaignInit({ commit }, params){    
        try {
            const res = await api.post(`/api/v1/subscribe/payment-campaign`, params);  
            commit('setClearError');
            return true;
        }
        catch (error) {
            if (error.response.status === 401) {
                localStorage.clear();
            } 
            else {
                commit('setError', error.response.data);
            }                      
            return false;
        }         
    },
    async acFreeCampaignInitCheck({ commit }, params){
        console.log('acSubscribeDate param', params)
        try {
            const res = await api.post(`/api/v1/campaign/payment/complete-free`, params);
            return res.data.code === 0;
        }
        catch (error) {
            return false;                   
        }         
    },
    async acCampaignStatus({commit}, params){
        try {
            const res = await api.post(`/api/v1/user/campaign/status`, params);
            return res.data;
        }
        catch (error) {
            return false; 
        }
    }
}

export const getters = {
    geCodeFail: state => {
        switch(state._error.code) {
            case 6010:
                return '해당 주소지로 구독중인 알리미가 있습니다!';
            default:
                return state._error.message;
        }
    }
};


export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}